
/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import PageAnalytics from '../../../atomic/organisms/Page-Analytics/Page-Analyitics.vue';
import Table from '../../../atomic/organisms/Table/Table.vue';
import NavTabs from '../../../atomic/atoms/Nav-Tabs/Nav-Tabs.vue';
import { Tabs } from '../../../utilities/Types/navigation.types';
import PageControls from '../../../atomic/organisms/Page-Controls/Page-Controls.vue';
import { ColumnDefinition, SortDefinition } from '../../../utilities/Types/table.types';
import { IDropdownOption } from '../../../utilities/Interfaces/form.interfaces';
import axios from 'axios';
import router from '@/router';
import { AbortHandler } from '@/services/AbortHandler';

export default defineComponent({
  components: {
    'arc-page-analytics': PageAnalytics,
    'arc-table': Table,
    'arc-nav-tabs': NavTabs,
    'arc-page-controls': PageControls,
  },
  async mounted(): Promise<void> {
    await this.resetPaginationState();

    const customUrl = new Map<string, string>();
    const url = window.location.href;
    let requestId: string | null = null;

    if (new URL(url).pathname.includes('/cm/mm/')) {
      const urlToken = new URL(url).pathname.split('/').pop();
      if (urlToken) {
        requestId = urlToken;
      }
    }

    if (requestId) {
      this.requestId = requestId;
      const urlInformation = await this.fetchCommunicationMallsInformationViaPublicId(requestId);

      if (!urlInformation.valid) {
        this.loading = false;
        return;
      }

      const queryParameters = urlInformation.data.queryParams;

      for (const query of queryParameters.replace('?', '').split('&')) {
        const [key, value] = query.split('=');
        customUrl.set(key, value);
      }

      this.contactGroupId = customUrl.get('contactGroup') as string;
    } else {
      this.contactGroupId = this.$route.query.contactGroup as string;
    }
    const response = await this.fetchMallMallMemos(this.contactGroupId);
    this.total = response?.total;

    // if (this.$route.query.memoId) {
    //   this.updateModalComponent('mall-memo');
    //   this.updateModalType('large');
    //   this.updateModalData({ campaignId: this.$route.query.memoId, mode: 'edit', });
    //   this.openModal();
    // }
  },
  watch: {
    pageValue: {
      deep: true,
      async handler() {
        await this.updatePaginationPage(this.pageValue.value as number);
        if (!this.pageReset) {
          const response = await this.fetchMallMallMemos(this.contactGroupId);
          this.total = response?.total;
        }
        this.pageReset = false;
      },
    },
    showValue: {
      deep: true,
      async handler() {
        await this.updatePaginationLimit(this.showValue.value as number);
        const response = await this.fetchMallMallMemos(this.contactGroupId);
        this.total = response?.total;
      },
    },
    searchFilter: {
      deep: true,
      async handler() {
        await this.updatePaginationSearch(this.searchFilter);
        const response = await this.fetchMallMallMemos(this.contactGroupId);
        this.total = response?.total;
      },
    },
    sortDefinition: {
      deep: true,
      async handler() {
        await this.updatePaginationSortKey(this.sortDefinition.column);
        await this.updatePaginationSortDirection(this.sortDefinition.direction);
        const response = await this.fetchMallMallMemos(this.contactGroupId);
        this.total = response?.total;
      },
    },
  },
  computed: {
    ...mapGetters(['access', 'permissions', 'mallMemos', 'loadingMallMemos']),
    isLoading(): boolean {
      return this.loading || this.loadingMallMemos;
    },
    showSearch(): boolean {
      return this.access && !this.access?.mallMemosNone;
    },
    columnDefinitions(): ColumnDefinition[] {
      return [
        {
          type: 'text',
          columnName: 'secondaryStatusText',
          displayName: 'Action Needed',
          cellStyle: 'font-weight: bold; text-transform: uppercase;',
        },
        {
          type: 'review',
          columnName: 'reviewPageUrl',
          displayName: 'Review Page Url',
        },
        {
          type: 'text',
          columnName: 'createdByUserName',
          displayName: 'Created By',
        },
        {
          type: 'date-time-localized',
          columnName: 'createdAt',
          displayName: 'Created At',
        },
        {
          type: 'text',
          columnName: 'memoNumber',
          displayName: 'Memo ID',
          sortable: true,
        },
        {
          type: 'text',
          columnName: 'commSubTypeText',
          displayName: 'Type',
          sortable: true,
        },
        {
          type: 'text',
          columnName: 'brandText',
          displayName: 'Brand',
          sortable: true,
        },
        {
          type: 'text',
          columnName: 'statusText',
          displayName: 'Status',
          sortable: true,
        },
        {
          type: 'text',
          columnName: 'parsedHeadline',
          displayName: 'Headline',
        },
        {
          type: 'image',
          columnName: 'imageUrl',
          displayName: 'Image',
          position: 'center',
        },
        {
          type: 'date',
          columnName: 'reviewByDate',
          displayName: 'Review By Date',
        },
        {
          type: 'date',
          columnName: 'fromDate',
          displayName: 'Start Date',
          sortable: true,
        },
        {
          type: 'date',
          columnName: 'toDate',
          displayName: 'End Date',
          sortable: true,
        },
        {
          type: 'questions',
          columnName: 'questionsLength',
          displayName: 'Questions',
          position: 'center',
        },
        {
          type: 'approval',
          columnName: 'stats',
          displayName: 'Approval',
          headerStyle: 'min-width: 525px',
        },
      ];
    },
    tableData(): unknown {
      this.finishLoading();
      return this.mallMemos;
    },
    tableOptions(): any {
      return {
        clickable: true,
      };
    },
    tabs(): Tabs {
      return [
        {
          title: 'ALL',
          route: `/cm/all/${this.requestId}`,
          activeRouteCheck: '/cm/all',
        },
        {
          title: 'MARKETING CAMPAIGNS',
          route: `/cm/mc/${this.requestId}`,
          activeRouteCheck: '/cm/mc',
        },
        {
          title: 'RECRUITMENT CAMPAIGNS',
          route: `/cm/rc/${this.requestId}`,
          activeRouteCheck: '/cm/rc',
        },
        {
          title: 'MALL MEMOS',
          route: `/cm/mm/${this.requestId}`,
          activeRouteCheck: '/cm/mm',
        },
      ];
    },
    pageOptions(): IDropdownOption[] {
      let total: any = this.total;
      let showValue: any = this.showValue.value;
      let options = [];
      let numberOfPages = Math.ceil(total / showValue);
      for (let i = 0; i < numberOfPages; i++) {
        options.push({ description: `${i + 1}`, value: i + 1 });
      }
      return options;
    },
    totalPages(): number {
      let total: any = this.total;
      let showValue: any = this.showValue.value;
      return Math.ceil(total / showValue);
    },
  },

  data(): {
    statusFilterValue: IDropdownOption;
    statusFilterOptions: IDropdownOption[];
    searchFilter: string;
    showValue: IDropdownOption;
    showOptions: IDropdownOption[];
    pageValue: IDropdownOption;
    pageReset: boolean;
    allTableData: any;
    sortDefinition: SortDefinition;
    loading: boolean;
    total: number;
    buttonFeedback: {
      exportLoading: boolean;
      exportSubmitted: boolean;
    };
    requestId: string;
    contactGroupId: string;
  } {
    return {
      statusFilterValue: { description: 'All', value: 'all' },
      statusFilterOptions: [
        { description: 'All', value: 'all' },
        { description: 'Active', value: 'active' },
        { description: 'Upcoming', value: 'upcoming' },
        { description: 'Expired', value: 'expired' },
        { description: 'Draft', value: 'draft' },
      ],
      searchFilter: '',
      showValue: { description: '25', value: 25 },
      showOptions: [
        { description: '10', value: 10 },
        { description: '25', value: 25 },
        { description: '50', value: 50 },
        { description: '100', value: 100 },
      ],
      pageValue: { description: '1', value: 1 },
      pageReset: false,
      allTableData: [],
      sortDefinition: { column: 'id', type: 'text', direction: 'DESC' },
      loading: true,
      total: 0,
      buttonFeedback: {
        exportLoading: false,
        exportSubmitted: false,
      },
      requestId: '',
      contactGroupId: '',
    };
  },

  methods: {
    ...mapActions([
      'openModal',
      'updateModalType',
      'updateModalComponent',
      'updateModalData',
      'clearSelectedRecruitmentCampaign',
      'clearSelectedMarketingCampaign',
      'clearSelectedMallMemo',
      'fetchMallMallMemos',
      'updatePaginationPage',
      'updatePaginationLimit',
      'updatePaginationSearch',
      'updatePaginationSortKey',
      'updatePaginationSortDirection',
      'resetPaginationState',
      'fetchCommunicationMallsInformationViaPublicId',
    ]),
    startLoading(): void {
      this.loading = true;
    },
    finishLoading(): void {
      this.loading = false;
    },
    resetPageValue(): void {
      this.pageReset = true;
      this.pageValue = { description: '1', value: 1 };
    },
    updateAllTableData(data: any): void {
      this.allTableData = data;
    },
    updateStatusFilter(filter: IDropdownOption) {
      this.resetPageValue();
      this.statusFilterValue = filter;
    },
    async sort(payload: SortDefinition) {
      this.resetPageValue();
      this.sortDefinition = payload;
    },
    async updateShow(filter: IDropdownOption) {
      this.resetPageValue();
      this.showValue = filter;
    },
    async updatePage(filter: IDropdownOption) {
      this.pageValue = filter;
    },
    async previousPage() {
      let pageValue: any = this.pageValue;
      this.pageValue = {
        description: `${pageValue.value - 1}`,
        value: pageValue.value - 1,
      };
    },
    async nextPage() {
      let pageValue: any = this.pageValue;
      this.pageValue = {
        description: `${pageValue.value + 1}`,
        value: pageValue.value + 1,
      };
    },
    async search(searchFilter: string): Promise<void> {
      const abortController = AbortHandler.getInstance();
      abortController.kill();

      this.resetPageValue();
      this.searchFilter = searchFilter;
    },
    // create(): void {
    //   this.clearSelectedRecruitmentCampaign();
    //   this.clearSelectedMarketingCampaign();
    //   this.clearSelectedMallMemo();
    //   this.updateModalData(null);
    //   this.updateModalComponent('select');
    //   this.updateModalType('select-communications');
    //   this.openModal();
    // },
    // reports(): void {
    //   this.$router.push('/reports/mall-memos');
    // },
    // async export(): Promise<void> {
    //   this.buttonFeedback.exportSubmitted = true;
    //   this.buttonFeedback.exportLoading = false;
    //   const URL = `${process.env.VUE_APP_URI}/exports/mall-memos`;
    //   this.$notify({
    //     title: 'Export is in progress.',
    //     text: `You will receive an email with the required export file attached once it is ready. You can continue working in the app.`,
    //     type: 'info',
    //     duration: 12000,
    //   });
    //   setTimeout(() => {
    //     this.buttonFeedback.exportSubmitted = false;
    //   }, 12000);
    //   await axios.get(URL, {
    //     withCredentials: true,
    //   });
    // },
    // downloadAnswers(row: any): void {
    //   const link = document.createElement('a');
    //   link.target = '_target';
    //   let access = 'all';
    //   if (this.access?.mallMemosCompany) {
    //     access = 'company';
    //   }
    //   if (this.access?.mallMemosBrand) {
    //     access = 'brand';
    //   }
    //   if (this.access?.mallMemosMall) {
    //     access = 'mall';
    //   }
    //   link.href = `${process.env.VUE_APP_URI}/memos/answers/export/${row?.memoId}/${this.user?.userId}/${access}`;
    //   link.click();
    // },
    rowClicked(row: any): void {
      if (row.reviewPageUrl) {
        window.open(row.reviewPageUrl, '_blank');
      }
    },
    approvalClicked(payload: { status: string; type: string; row: any }): void {
      // do nothing
    },
  },
});
